@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
@import 'https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css';
@import 'https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css';

body {
  overscroll-behavior-y: none;
}
/* Chrome, Safari, Edge, Opera */
input.number-only-field::-webkit-outer-spin-button,
input.number-only-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.number-only-field[type='number'] {
  -moz-appearance: textfield;
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link:before {
  right: calc(50% + 20px);
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link.active:before,
.nav.nav-wizards-1 .nav-item:last-child .nav-link.completed:before {
  background: #348fe2;
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link:after {
  display: none;
}

.btn-dark-green {
  background-color: 'green' !important;
}

.p-min-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
