/*
   1.0 Color system
   2.0 RTL Setting
   3.0 Component Color Setting 
   4.0 Grid & Container Setting
   5.0 Body Setting
   6.0 App Component Setting
   7.0 z-index Setting
   8.0 Font Setting
   9.0 Radius Setting
  10.0 Caret Setting
  11.0 Heading / Paragraph Setting
  12.0 Pagination Setting
  13.0 Button Setting
  14.0 Form Component Setting
  15.0 Input Setting
  16.0 Table Setting
  17.0 Modal Setting
  18.0 List Group Setting
  19.0 Tabs Setting
  20.0 Alert Setting
  21.0 Card Setting
  22.0 Dropown Setting
  23.0 Progress Setting
*/

// 1.0 Color system

$amber: #ffc107 !default;
$black: #000000 !default;
$blue: #2196f3 !default;
$blue-grey: #607d8b !default;
$blue-grey-50: #eceff1 !default;
$blue-grey-100: #cfd8dc !default;
$blue-grey-200: #b0bec5 !default;
$blue-grey-300: #90a4ae !default;
$blue-grey-400: #78909c !default;
$blue-grey-500: $blue-grey !default;
$blue-grey-600: #546e7a !default;
$blue-grey-700: #455a64 !default;
$blue-grey-800: #37474f !default;
$blue-grey-900: #263238 !default;
$brown: #795548 !default;
$cyan: #00bcd4 !default;
$dark: #212121 !default;
$dark-darker: #000000 !default;
$deep-purple: #673ab7 !default;
$deep-orange: #ff5722 !default;
$deep-blue: #003370 !default;
$green: #8bc34a !default;
$grey: #9e9e9e !default;
$gold: #e9c94c !default;
$indigo: #3f51b5 !default;
$lime: #cddc39 !default;
$light: #f2f3f4 !default;
$light-green: #8bc34a !default;
$orange: #ff9800 !default;
$purple: #9c27b0 !default;
$pink: #e91e63 !default;
$red: #f44336 !default;
$silver: #eeeeee !default;
$teal: #009688 !default;
$white: #ffffff !default;
$yellow: #ffeb3b !default;
$inverse: $dark !default;
$link-color: $blue !default;
$gray-100: mix($white, $dark, 95%) !default;
$gray-200: mix($white, $dark, 90%) !default;
$gray-300: mix($white, $dark, 80%) !default;
$gray-400: mix($white, $dark, 65%) !default;
$gray-500: mix($white, $dark, 50%) !default;
$gray-600: mix($white, $dark, 40%) !default;
$gray-700: mix($white, $dark, 25%) !default;
$gray-800: mix($white, $dark, 10%) !default;
$gray-900: $dark !default;
$colors: (
  'amber': $amber,
  'black': $black,
  'blue': $blue,
  'blue-grey': $blue-grey,
  'blue-dark': $blue-grey-900,
  'cyan': $cyan,
  'dark': $dark,
  'dark-darker': $dark-darker,
  'deep-orange': $deep-orange,
  'deep-purple': $deep-purple,
  'deep-blue': $deep-blue,
  'green': $green,
  'grey': lighten($grey, 20%),
  'gold': $gold,
  'indigo': $indigo,
  'lime': $lime,
  'light': $light,
  'light-green': $light-green,
  'orange': $orange,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'silver': $silver,
  'teal': $teal,
  'white': $white,
  'yellow': $yellow,
  'muted': darken($silver, 30%),
  'aqua': $cyan,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900
);
$theme-colors: (
  'dark': $dark,
  'inverse': $dark,
  'black': $black,
  'white': $white,
  'grey': $silver,
  'muted': $silver,
  'silver': $silver,
  'lime': $lime,
  'aqua': $cyan
);
$primary-color: 'deep-blue' !default;
$primary: map-get($colors, $primary-color) !default;
$secondary-color: 'gold' !default;
$secondary: map-get($colors, $secondary-color) !default;
$success: $teal !default;
$warning: $orange !default;

// 2.0 RTL Setting

$enable-rtl: false !default;

// 3.0 Component Color Setting

$component-active-bg: $blue !default;
$component-active-color: $white !default;
$custom-control-indicator-checked-disabled-bg: rgba($blue, 0.5) !default;

// 4.0 Grid & Container Setting

$grid-breakpoints: (
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1660px,
  xxxl: 1900px
);
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1170px
) !default;
$spacer: rem(16px) !default;
$grid-gutter-width: 30px !default;

// 5.0 Body Setting

$body-bg: #eeeeee !default;
$body-color: lighten($black, 20%);
$body-letter-spacing: 0 !default;
$border-color: #cccccc !default;

// 6.0 App Component Setting

$boxed-layout-width: rem(1280px) !default;
$content-padding-x: rem(30px) !default;
$content-padding-y: rem(25px) !default;
$content-padding-x-sm: rem(20px) !default;
$content-padding-y-sm: rem(20px) !default;
$header-height: rem(60px) !default;
$header-height-sm: rem(50px) !default;
$sidebar-width: rem(220px) !default;
$sidebar-minified-width: rem(60px) !default;
$sidebar-wide-width: rem(250px) !default;
$top-menu-height: rem(46px) !default;

// 7.0 z-index Setting

$btn-scroll-to-top-zindex: 1020 !default;
$content-zindex: 1020 !default;
$float-submenu-zindex: 1020 !default;
$footer-zindex: 1020 !default;
$header-zindex: 1020 !default;
$header-fixed-zindex: 1040 !default;
$sidebar-zindex: 1010 !default;
$top-menu-zindex: 1015 !default;
$theme-panel-zindex: 1035 !default;
$page-loader-zindex: 9999 !default;
$page-title-zindex: 1020 !default;
$pace-loader-zindex: 1030 !default;
$panel-expand-zindex: 1080 !default;

// 8.0 Font Setting

$font-family-base: 'Roboto', sans-serif !default;
$font-size-base: rem-default(13px) !default;
$font-size-sm: rem-default(11px) !default;
$font-size-lg: rem-default(14px) !default;
$line-height-base: 1.5 !default;
$line-height-sm: 1.4 !default;
$line-height-lg: 1.8 !default;

// 9.0 Radius Setting

$border-radius: 3px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 3px !default;

// 10.0 Caret Setting

$enable-caret: false !default;

// 11.0 Heading / Paragraph Setting

$headings-font-weight: 500 !default;
$paragraph-margin-bottom: rem(15px) !default;

// 12.0 Pagination Setting

$pagination-border-width: 1px !default;
$pagination-color: lighten($dark, 30%) !default;
$pagination-hover-color: $dark !default;
$pagination-hover-border-color: $border-color !default;
$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $component-active-bg !default;
$pagination-focus-box-shadow: 0 !default;

// 13.0 Button Setting

$btn-focus-width: 0 !default;
$btn-box-shadow: 0 !default;
$btn-border-width: 1px !default;

// 14.0 Form Component Setting

$form-component-active-bg: $blue;
$form-component-active-color: $blue;
$form-feedback-font-size: rem(11px) !default;
$form-feedback-tooltip-font-size: rem(11px) !default;

// 15.0 Input Setting

$input-border-width: 1px !default;
$input-focus-border-color: #5db0ff !default;
$input-btn-padding-y: rem-default(7px) !default;
$input-btn-padding-y-sm: 0.3125rem !default;
$input-btn-padding-x-sm: 0.625rem !default;
$input-btn-font-size-sm: 0.75rem !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-color: $dark !default;
$input-border-color: $border-color !default;
$input-group-addon-bg: $border-color !default;
$input-group-addon-border-color: $border-color !default;

// 16.0 Table Setting

$table-cell-padding: rem(8px) !default;
$table-cell-padding-sm: rem(5px) !default;
$table-accent-bg: lighten($dark, 80%) !default;
$table-hover-bg: lighten($dark, 80%) !default;
$table-border-color: lighten($dark, 70%) !default;
$table-component-active-bg: $blue !default;
$table-component-active-color: $blue !default;

// 17.0 Modal Setting

$modal-header-padding-y: rem(15px) !default;
$modal-header-padding-x: rem(15px) !default;

// 18.0 List Group Setting

$list-group-border-color: rgba($dark, 0.125) !default;
$list-group-item-padding-y: rem(10px) !default;
$list-group-item-padding-x: rem(15px) !default;

// 19.0 Tabs Setting

$nav-tabs-border-width: 0 !default;
$nav-tabs-link-active-bg: $white !default;

// 20.0 Alert Setting

$alert-padding-y: 0.9375rem !default; // 15px
$alert-padding-x: 0.9375rem !default; // 15px
$alert-margin-bottom: 1.25rem !default; // 20px
$alert-border-width: 0 !default;

// 21.0 Card Setting

$card-spacer-y: rem(15px) !default;
$card-spacer-x: rem(15px) !default;
$card-img-overlay-padding: rem(15px) !default;
$card-columns-gap: rem(15px) !default;
$card-border-color: rgba($dark, 0.15) !default;
$card-cap-bg: lighten($dark, 75%) !default;

// 22.0 Dropown Setting

$dropdown-border-width: 0 !default;
$dropdown-font-size: $font-size-sm !default;
$dropdown-box-shadow: 0 13px 25px -2px rgba($black, 0.2);

// 23.0 Progress Setting

$progress-bar-bg: $blue !default;
$progress-bg: lighten($dark, 70%) !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: none !default;
$progress-bar-color: $white !default;
