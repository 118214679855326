@include keyframes(inToRight) {
  to {
    @include transform(translate3d(0, 0, 0));
  }
}

.sidebar {
  width: $sidebar-width;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: $header-height;
  background: $blue-grey-800;
  z-index: $sidebar-zindex;

  @include transform(translateZ(0));
  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    left: $sidebar-width * -1;
    z-index: $header-zindex + 1;

    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }

  & .nav {
    display: block;

    @if $enable-rtl {
      padding-right: 0;
    }

    & > li {
      position: relative;

      @include clearfix();

      & > a {
        padding: rem(10px) rem(20px);
        line-height: rem(20px);
        color: $blue-grey-200;
        display: block;
        text-decoration: none;
        font-weight: 500;

        @include clearfix();

        & i {
          float: left;
          margin-right: rem(10px);
          margin-top: rem(-2px);
          margin-bottom: rem(-2px);
          width: rem(24px);
          height: rem(24px);
          text-align: center;
          line-height: rem(24px);
          font-size: rem(24px);
          color: $blue-grey-600;

          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: rem(10px);
          }
        }
        & .icon-img {
          float: left;
          margin-right: rem(10px);
          margin-top: rem(-2px);
          margin-bottom: rem(-2px);
          width: rem(24px);
          height: rem(24px);
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include border-radius($border-radius);
          @include display-flex();
          @include flex-align(center);
          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: rem(10px);
          }

          & img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        & .badge {
          padding: rem(3px) rem(8px) rem(2px);
          line-height: rem(15px);
          background: mix($black, $blue-grey, 72%);
          font-weight: 500;
          font-size: rem(12px);
        }
        & .label {
          padding-top: 0;
          padding-bottom: 0;
          margin-left: rem(8px);

          &.label-theme {
            @include gradient-enabled {
              @include gradient-linear(
                to bottom,
                (lighten($secondary, 5%) 0%, darken($secondary, 5%) 100%)
              );
            }
          }

          @if $enable-rtl {
            margin-left: 0;
            margin-right: rem(8px);
          }
        }
        & > span {
          @include display-flex();
          @include flex(1);
          @include flex-wrap(wrap);
        }
        &:hover,
        &:focus {
          background: none;
          color: $white;
        }
      }
      &.active {
        & > a {
          position: relative;
          z-index: 10;
          color: $white;
          background: $secondary;

          @include gradient-enabled {
            @include gradient-linear(
              to bottom,
              (lighten($secondary, 8%) 0%, darken($secondary, 8%) 100%)
            );
          }

          & i {
            color: $white;
          }
          & .caret {
            color: $white;
          }
          & .label,
          & .badge {
            @if ($secondary-color == 'black') {
              background: rgba($white, 0.35);
            } @else {
              background: rgba($black, 0.65);
            }

            @include gradient-enabled {
              @if ($secondary-color == 'black') {
                background: rgba($white, 0.35);
              } @else {
                background: rgba($black, 0.65);
              }
            }
          }
        }
      }
      &.nav-header {
        margin: 0;
        padding: rem(15px) rem(20px) rem(5px);
        line-height: rem(20px);
        font-size: rem(13px);
        color: $white;
        font-weight: 400;

        & a {
          padding: 0;
          margin: 0;
          display: inline;

          &:hover,
          &:focus {
            background: none;
            color: $white;
          }
          & i {
            float: none;
            margin: 0;
          }
        }
      }
      &.nav-profile {
        padding: rem(20px);
        color: $white;
        background: $dark-darker;
        overflow: hidden;

        & a {
          padding: 0;
        }
        & > a {
          margin: rem(-20px);
          padding: rem(20px);
          display: block;
          color: $white;
          font-weight: 500;

          &:hover {
            & .cover {
              &.with-shadow {
                &:before {
                  opacity: 0.75;
                }
              }
            }
          }
        }
        & .image {
          width: rem(50px);
          height: rem(50px);
          margin-bottom: rem(10px);
          overflow: hidden;
          position: relative;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include display-flex();
          @include flex-align(center);
          @include border-radius($border-radius-lg * 10);

          & img {
            max-width: 100%;
            max-height: 100%;
          }
          &.image-icon {
            & i {
              float: none;
              display: block;
              font-size: rem(50px);
              line-height: rem(50px);
              text-align: center;
              width: auto;
              margin: 0 auto rem(5px);
            }
          }
        }
        & .info {
          font-size: rem(14px);
          position: relative;

          & .caret {
            margin-top: rem(2px);
          }
          & small {
            display: block;
            color: $blue-grey-300;
            font-size: rem(13px);
            line-height: rem(16px);
            margin-bottom: rem(-5px);
          }
        }
        & .cover {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url(../images/cover-sidebar-user.jpg);
          background-repeat: no-repeat;
          background-size: cover;

          &.with-shadow {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: rgba(23, 35, 41, 0.75);

              @include transition(all 0.2s linear);
            }
          }
        }
      }
      &.nav-search {
        padding: rem(20px);

        & + li {
          padding-top: 0;
        }
        & .form-control {
          background: none;
          border: none;
          padding-left: 0;
          padding-right: 0;
          border-bottom: 1px solid rgba($white, 0.2);
          color: $white;

          @include border-radius(0);

          &:focus {
            border-color: rgba($white, 0.5);

            @include box-shadow(none);
          }
        }
      }
      &.nav-widget {
        padding: rem(10px) rem(20px);

        & i {
          margin-right: auto;
        }
      }
      &.has-sub {
        & > .sub-menu {
          background: rgba($black, 0.25);
        }
      }
      & .text-theme {
        margin-left: rem(5px);

        @if $enable-rtl {
          margin-right: rem(5px);
          margin-left: 0;
        }
      }
    }
    & .has-sub {
      & > .sub-menu {
        & > li {
          @if $enable-rtl {
            @include transform(translate3d(100%, 0, 0));
          } @else {
            @include transform(translate3d(-100%, 0, 0));
          }
        }
      }
      &.active {
        & > .sub-menu {
          display: block;
        }
      }
      &.expand,
      &.active {
        & > .sub-menu {
          & > li {
            @include animation(inToRight 0.3s both cubic-bezier(0.7, 0, 0.3, 1));
          }
        }
      }
    }
    & .sub-menu {
      & > li {
        & > a {
          color: $blue-grey-300;

          & .caret {
            margin-top: rem(-1px);
            left: rem(-5px);
            color: rgba($white, 0.25);
          }
        }
      }
    }
    &.nav-profile {
      padding: rem(5px) 0;
      border-bottom: 1px solid $blue-grey-900;
      display: none;
      &.active {
        display: block;
      }
      & > li {
        @if $enable-rtl {
          @include transform(translate3d(100%, 0, 0));
        } @else {
          @include transform(translate3d(-100%, 0, 0));
        }
      }
      &.expand,
      &.active {
        & > li {
          @include animation(inToRight 0.3s both cubic-bezier(0.7, 0, 0.3, 1));
        }
      }
    }

    @for $i from 2 through 50 {
      $time: 15 + (15 * $i);
      $delay: $time * 1ms;

      &.nav-profile.epxand > li,
      & .has-sub.expand > .sub-menu > li {
        &:nth-child(#{$i}) {
          -webkit-animation-delay: $delay;
          animation-delay: $delay;
        }
      }
    }
  }
  & li {
    & > a {
      & .caret {
        display: block;
        float: right;
        width: rem(20px);
        height: rem(20px);
        line-height: rem(20px);
        text-align: center;
        font-size: rem(16px);
        border: none;

        @include fontawesome();
        @if $enable-rtl {
          float: left;
        }

        &:before {
          content: '\f0da';
          display: block;
          text-align: center;
          opacity: 0.5;

          @include transition(all 0.2s linear);
          @if $enable-rtl {
            content: '\f0d9';
          }
        }
      }
    }

    &.active,
    &.expanding,
    &.expand {
      & > a {
        & .caret {
          &:before {
            opacity: 1;

            @include transform(rotate(90deg));
            @if $enable-rtl {
              @include transform(rotate(-90deg));
            }
          }
        }
      }
    }
    &.closed,
    &.closing {
      & > a {
        & .caret {
          &:before {
            @include transform(rotate(0deg));
          }
        }
      }
    }
  }
  & .sub-menu {
    list-style-type: none;
    padding: rem(10px) 0;
    margin: 0;
    position: relative;
    display: none;

    @if $enable-rtl {
      padding-left: 0;
      padding-right: rem(39px);
    }

    & > li {
      position: relative;

      & > a {
        padding: rem(3px) rem(20px) rem(3px) rem(54px);
        display: block;
        color: rgba($white, 0.6);
        text-decoration: none;
        position: relative;

        @if $enable-rtl {
          padding-right: rem(15px);
          padding-left: rem(20px);
        }

        &:hover,
        &:focus {
          color: $white;
        }
      }
      &.active {
        & > a {
          color: $white;
        }
      }
    }
    & .sub-menu {
      padding: 0 0 0 rem(15px);
      background: none;

      @if $enable-rtl {
        padding-left: 0;
        padding-right: rem(15px);
      }
    }
  }
  & .sidebar-minify-btn {
    margin: rem(10px) 0;
    float: right;
    padding: rem(5px) rem(20px) rem(5px) rem(10px) !important;
    background: $blue-grey-900;
    color: $white;

    @include border-radius($border-radius * 5 0 0 $border-radius * 5);
    @if $enable-rtl {
      float: left;

      @include border-radius(0 $border-radius * 5 $border-radius * 5 0);
    }

    & i {
      margin: 0 !important;
      color: $white;
    }
    &:hover,
    &:focus {
      background: darken($blue-grey-900, 8%) !important;
    }
  }
  & .slimScrollDiv {
    & .slimScrollBar {
      &:before {
        background: rgba($white, 0.5) !important;
      }
    }
  }
  &.sidebar-grid {
    & .nav {
      & > li {
        & + li {
          margin-top: -1px;
        }
        & > a {
          border-top: 1px solid $blue-grey-900;
          border-bottom: 1px solid $blue-grey-900;
        }

        &.expanding,
        &.expand {
          & > a {
            border-bottom-color: transparent;
          }
        }
        &.active {
          & > a {
            border-color: $dark;
          }
        }
      }
    }
  }
  &.sidebar-transparent {
    background: none;

    & + .sidebar-bg {
      background: url(../images/cover-sidebar.jpg);
      background-size: cover;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($dark, 0.75);
      }
    }
    & .nav {
      & > li {
        &.active {
          & > a {
            background: rgba($black, 0.3);
          }
        }
        &.nav-profile {
          background: rgba($black, 0.25);

          & a {
            &:hover,
            &:focus {
              background: none;
            }
          }
          & .cover {
            background: none;

            &.with-shadow {
              &:before {
                background: none;
              }
            }
          }
          &.active {
            background: rgba($black, 0.25);
          }
        }
      }
    }
    & .sub-menu {
      background: none;

      & > li {
        &:before {
          background: rgba($black, 0.45);
        }
        & > a {
          &:after {
            background: rgba($black, 0.45);
          }
        }
        &.has-sub {
          & > a {
            &:after {
              background: rgba($black, 0.45);
            }
          }
        }
      }
    }
  }
}
.sidebar-bg {
  background: $blue-grey-800;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  z-index: $sidebar-zindex - 10;

  @include box-shadow(
    (
      0 2px 2px 0 rgba($black, 0.14),
      0 3px 1px -2px rgba($black, 0.2),
      0 1px 5px 0 rgba($black, 0.12)
    )
  );
  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    left: $sidebar-width * -1;
    z-index: $header-zindex;

    @include box-shadow(none);
    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }
}
