.ui-widget {
	font-family: inherit;
	
	&.ui-autocomplete {
		padding: rem(5px) 0;
		border: none;
		font-size: rem(12px);
		
		@include box-shadow(0 8px 16px rgba($black, .15));
		@include border-radius(0 0 $border-radius $border-radius);
		
		& .ui-menu-item-wrapper {
			padding: rem(5px) rem(12px);
			
			&.ui-state-active {
				background: $form-component-active-bg;
				border: none;
				color: $white;
				margin: 0;
				
				@include border-radius(0);
			}
		}
	}
}